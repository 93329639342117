import { MoonMask, MoonOrSun, IconWrapper } from './DarkModeToggle.styles'
import React from 'react'
import { useColorMode } from 'theme-ui'

const DarkModeToggle = () => {
  const [colorMode, setColorMode] = useColorMode();
  const isDark = colorMode === `dark`;

  function toggleColorMode(event) {
    event.preventDefault();
    setColorMode(isDark ? `light` : `dark`);
  }

  return (
      <IconWrapper
          isDark={isDark}
          onClick={toggleColorMode}
          data-a11y="false"
          aria-label={isDark ? "Activate light mode" : "Activate dark mode"}
          title={isDark ? "Activate light mode" : "Activate dark mode"}
      >
        <MoonOrSun isDark={isDark} />
        <MoonMask isDark={isDark} />
      </IconWrapper>
  );
};

export default DarkModeToggle;
