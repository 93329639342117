import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
//import { useColorMode } from 'theme-ui'
import PropTypes from 'prop-types'
import DarkModeToggle from './DarkModeToggle'
import { Navigation } from './Header.styles'
// import Logo from './Logo';
import resume from '../files/Nina+Yujiri+Resume.pdf'

const Header = ({siteTitle}) => {
  const [toggleState, setToggleState] = useState('');
  // const [colorMode] = useColorMode();
  // const fill = colorMode === 'dark' ? '#f7f7f7' : '#333';

  useEffect(() => {
    const handleResize = () => setToggleState('');
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  function toggle() {
    setToggleState(toggleState === '' ? 'is-active' : '');
  }

  return (
      <Navigation>
        <nav className="navbar is-transparent">
          <div className="navbar-brand">
            <h6 className="navbar-item site-title">
              <Link to="/">
                  {/*<Logo fill={fill}/>*/}
                  Nina Yujiri
              </Link>
            </h6>
            <div className={ `navbar-burger burger ${ toggleState }` }
                 data-target="navbarTransparent"
                 onClick={ toggle }
            >
              <span />
              <span />
              <span />
            </div>
          </div>

          <div id="navbarTransparent"
               className={ `navbar-menu is-transparent ${ toggleState }` }
          >
            <div className="navbar-end">
              <h6 className="nav-link">
                <Link to="/"
                      activeClassName="active">
                  Work
                </Link>
              </h6>

              <h6 className="nav-link">
                <Link to="/about"
                      activeClassName="active">
                  About
                </Link>
              </h6>

              <h6 className="nav-link">
                <Link to="/contact"
                      activeClassName="active">
                  Contact
                </Link>
              </h6>

              <h6 className="nav-link">
                <a href={ resume }
                   target="_blank"
                   rel="noopener noreferrer"
                >
                  Resume
                </a>
              </h6>

              <div className="nav-link">
                <DarkModeToggle />
              </div>
            </div>
          </div>
        </nav>
      </Navigation>
  );
};
Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header
