import React from 'react'
import styled from '@emotion/styled'

const Link = styled.a`
  color: ${ props => props.theme.colors.text };
  opacity: .8;
  text-decoration: underline;

  &:hover {
    color: ${ props => props.theme.colors.text };
    opacity: 1;
  }
`;

const ExternalLink = ({
                        externalUrl,
                        linkText
                      }) => (
    <Link
        href={ externalUrl }
        target="_blank"
        rel="noopener noreferrer">
      { linkText }
    </Link>
);

export default ExternalLink;
