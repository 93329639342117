import styled from '@emotion/styled'

export const IconWrapper = styled.button`
  opacity: 0.5;
  position: relative;
  border-radius: 5px;
  width: 40px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  margin-top: -3px;

  &:hover {
    opacity: 1;
  }

  &[data-a11y="true"]:focus::after {
    content: "";
    position: absolute;
    left: 0;
    top: -30%;
    width: 100%;
    height: 160%;
    border: 2px solid ${ props => props.theme.colors.accent };
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  @media only screen and (max-width: 1022px) {
    display: inline-flex;
    transform: scale(0.708);
  }
`;

// This is based off a codepen! Much appreciated to: https://codepen.io/aaroniker/pen/KGpXZo
export const MoonOrSun = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: ${ props => (props.isDark ? '4px' : '2px') } solid
    ${ props => props.theme.colors.text };
  background: ${ props => props.theme.colors.text };
  transform: scale(${ props => (props.isDark ? 0.55 : 1) });
  transition: all 0.45s ease;
  overflow: ${ props => (props.isDark ? 'visible' : 'hidden') };

  &::before {
    content: "";
    position: absolute;
    right: -9px;
    top: -9px;
    height: 20px;
    width: 20px;
    border: 2px solid ${ props => props.theme.colors.text };
    border-radius: 50%;
    transform: translate(${ props => (props.isDark ? '14px, -14px' : '0, 0') });
    opacity: ${ props => (props.isDark ? 0 : 1) };
    transition: transform 0.45s ease;
  }

  &::after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: -4px 0 0 -4px;
    position: absolute;
    top: 50%;
    left: 50%;
    box-shadow: 0 -23px 0 ${ props => props.theme.colors.text },
      0 23px 0 ${ props => props.theme.colors.text },
      23px 0 0 ${ props => props.theme.colors.text },
      -23px 0 0 ${ props => props.theme.colors.text },
      15px 15px 0 ${ props => props.theme.colors.text },
      -15px 15px 0 ${ props => props.theme.colors.text },
      15px -15px 0 ${ props => props.theme.colors.text },
      -15px -15px 0 ${ props => props.theme.colors.text };
    transform: scale(${ props => (props.isDark ? 1 : 0) });
    transition: all 0.35s ease;
  }
  
  @media only screen and (max-width: 1022px) {
    transform: scale(0.92);
  }
`;

export const MoonMask = styled.div`
  position: absolute;
  right: -1px;
  top: -8px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 0;
  background: ${ props => props.theme.colors.background };
  transform: translate(${ props => (props.isDark ? '14px, -14px' : '0, 0') });
  opacity: ${ props => (props.isDark ? 0 : 1) };
  transition: ${ props => props.theme.colorModeTransition }, transform 0.45s ease;
`;
