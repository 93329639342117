import React from 'react'
import ExternalLink from './ExternalLink'

const SocialLink = ({
                      platform,
                      externalUrl
                    }) => (
    <span style={ {
      paddingRight: `1.5em`
    } }>
      <ExternalLink
          externalUrl={ externalUrl }
          linkText={ platform }
      />
    </span>
);

export default SocialLink;
