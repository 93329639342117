import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { ThemeProvider, useColorMode  } from 'theme-ui'
import { globalStyles } from '../styles/global'

import Header from './Header'
import Footer from './Footer'
import theme from '../gatsby-plugin-theme-ui/index'

import '../styles/layout.css'
import { Background } from './Layout.styles';
import { Global } from '@emotion/core';

const Layout = ({children}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const [colorMode] = useColorMode();
  const selectedTheme = colorMode === 'light' ? theme : theme.colors.modes.dark;

  return (
      <ThemeProvider theme={ selectedTheme }>
        <Header siteTitle={ data.site.siteMetadata.title } />
        <Global styles={globalStyles} />

        <Background>
          <main>
            { children }
          </main>

          <Footer />
        </Background>
      </ThemeProvider>
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout
