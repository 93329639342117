import styled from '@emotion/styled'

export const Navigation = styled.header`
  padding: 1em;
  font-family: ${ props => props.theme.fonts.body };
  background-color: ${ props => props.theme.colors.background };
  transition: ${ props => props.theme.colorModeTransition };

  @media only screen and (min-width: 768px) {
    padding: 3em;
  }

  .site-title a {
    font-style: normal;
    font-size: 21px;
    font-family: ${ props => props.theme.fonts.body };
    color: ${ props => props.theme.colors.text };
    text-decoration: none;
  }

  .nav-link {
    font-style: normal;
    margin-right: 2.5em;
    font-size: 12px;
    letter-spacing: .05em;
    line-height: 2.4em;
    text-transform: uppercase;

    &:last-child {
      margin-right: 0;
      margin-top: 3px;
    }
    
    &:nth-child(4) {
      margin-right: 1.5em;
    }

    a {
      color: ${ props => props.theme.colors.text };
      text-decoration: none;
      font-weight: 400;
      background-size: 0 100%;
      background-repeat: no-repeat;

      &:hover, &.active {
        padding-bottom: 3px;
        border-bottom: 1px solid ${ props => props.theme.colors.text };
      }
    }
  }

  @media screen and (max-width: 1023px) {
    .navbar-burger {
      color: ${ props => props.theme.colors.text };
    }
    .navbar-menu {
      box-shadow: none !important;
      background-color: ${ props => props.theme.colors.background };
      transition: ${ props => props.theme.colorModeTransition };

      .nav-link {
        padding: 1em;
        text-align: center;
        margin-right: 0;
      }
    }
  }
`;
