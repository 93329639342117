import React from 'react'
import styled from '@emotion/styled'
import ExternalLink from './ExternalLink'
import SocialLink from './SocialLink'

const FooterContainer = styled.footer`
  font-family: ${ props => props.theme.fonts.body };
  font-weight: 400;
  font-style: normal;
  padding: 1em;
  text-align: center;
  font-size: 12px;
  margin: 3.5em;
  display: flex;
  justify-content: space-between;
  
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    
    .social-links {
      margin-right: -15px;
      margin-top: 10px;
    }
  }
`;

const Footer = () => (
    <FooterContainer>
      <div>
        © { new Date().getFullYear() } Nina Yujiri, Built with
        { ` ` }
        <ExternalLink
            externalUrl="https://www.gatsbyjs.org"
            linkText="Gatsby"
        />
      </div>
      <div className="social-links">
        <SocialLink
            platform="Github"
            externalUrl="https://github.com/ninayujiri"
        />
        <SocialLink
            platform="Instagram"
            externalUrl="https://instagram.com/ninayujiri"
        />
        <SocialLink
            platform="LinkedIn"
            externalUrl="https://www.linkedin.com/in/ninayujiri/"
        />
      </div>
    </FooterContainer>
);

export default Footer;
